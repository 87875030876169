import React, {Component} from 'react';
import { Parallax} from 'react-parallax/';
import {Fade , Reveal} from 'react-reveal/';
const image = require('../../image/IMG_20191216_171055585.jpg');

class Banner extends Component {
    render(){
        let jhonData = this.props.jhonData;
        return(
            <section id="home">
                <Parallax bgImage={image} strength={100} className="banner_area">
                    <div className="container">
                        <div className="banner_content">
                            <Reveal effect="fadeInUp" duration={1500}>
                                <h2>NWerks, Inc.</h2>
                            </Reveal>
                            
                            <h4 className="wow fadeInUp anoimated">We provide uncommon Strategic Technical Services for Financial Services, FinTechs and Startups.</h4>
                            <h4 className="wow fadeInUp anoimated">We do the hard parts so you can "Make it simple."</h4>
                        </div>
                    </div>
                </Parallax>
            </section>
        );
    }
}

export default Banner;