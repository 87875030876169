import React, {Component} from 'react';
import Clientsliders from 'react-slick';
import Sectiontitle from '../component/Banner/Sectiontitle';

class Clientslogo extends  Component{
    render(){
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        };
        return(
            <section className="clients_logo_area">
                <div className="container">
                    <Sectiontitle Title="Trusted by great brands" TitleP="NWerks has created successes for a broad range of FinTech and Financial Services companies."/>
                    <Clientsliders {...settings} className="clients_slider">
                        <div className="item">
                            <a href=".#"><img src={require('../image/edj.jpeg')} alt=""/></a>
                        </div>
                        <div className="item">
                            <a href=".#"><img src={require('../image/wbg.jpg')}alt=""/></a>
                        </div>
                        <div className="item">
                            <a href=".#"><img src={require('../image/mbl.png')} alt=""/></a>
                        </div>
                    </Clientsliders>
                </div>
            </section>
        );
    }
    
}
export default Clientslogo;