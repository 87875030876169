import React from 'react';
import Navbar from './component/Navbar/Navbar';
import Banner from './component/Banner/Banner';
import About from './component/About';
import Service from './component/Service';
import Clientslogo from './component/Clientslogo';
import Contact from './component/Contact';
import Footer from './component/Footer';
import jhonData from './component/jhonData';


export const Home = () => (
  <div className="body_wrapper">
      <Navbar mContainer="custome_container" mainlogo="nwerksw.png" stickylogo="nwerks.gif"/>  
      <Banner jhonData={jhonData}/>
      <About aClass="about_area" jhonData={jhonData}/> 
      <Service wClass="work_area" jhonData={jhonData}/>
      <Clientslogo/>
      <Contact jhonData={jhonData}/>
      <Footer jhonData={jhonData}/>
  </div>
)

