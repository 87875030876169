const jhonData = {
    "name": "John Deo.",
    "name2": "Richard Tea.",
    "name3": "Alexa Johnson",
    "role": "Visual Designer & Front-End Developer,",
    "ptext": "Creative Designer & Developer located in London.",
    "socialLinks":[
        {
          "name": "Facebook",
          "url": "http://www.linkedin.com/",
          "className": "social_facebook"
        },
        {
          "name":"twitter",
          "url":"http://www.twitter.com/",
          "className":"social_twitter"
        },
        {
          "name":"googleplus",
          "url":"http://www.googleplus.com",
          "className":"social_googleplus"
        },
        {
            "name":"pinterest",
            "url":"http://www.pinterest.com",
            "className":"social_pinterest"
          }
      ],
      "aboutme": "About Me",
      "aboutdetails": "I am Jhone Deo Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever It has survived not only five centuries, but also the leap into electronic but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing",
      "service":[
          {
            "id" : 1,
            "iconName": "icon-shield",
            "serviceTitle": "Data Security",
            "sDetails" : "Your vulnerabilities to attack are dominated by unknowns. It's what keeps you awake at night. We approach data security using a 'Forward Security' approach. We employ White Hat ethical 'hacking' to discover vulnerabilities and then work with clients to systemically create and strengthen multiple layers of defense."
          },
          {
            "id" : 2,
            "iconName": "icon-genius",
            "serviceTitle": "Systems Development",
            "sDetails" : "We build maintainable strategic platforms for clients, from the front end all the way through to high availability, micro service based backends. We've built production systems that have handled millions of transactions a day while clocking multi-year uptimes."
          },
          {
            "id" : 3,
            "iconName": "icon-puzzle",
            "serviceTitle": "Strategy",
            "sDetails" : "How you implement your next system is more important to your business than what you implement. We provide C-level consultative services for strategically positioning digital infrastructure for more 'at hand' capabilities that create opportunities for your business."
          },
          {
            "id" : 4,
            "iconName": "icon-linegraph",
            "serviceTitle": "Management Services",
            "sDetails" : "CTOs and CIOs should not just be your best programmer. These jobs require business and technical accumen and are thereby hard to fill. NWerks 'rents out' veteran CTOs and CIOs for both established FinTechs and startups. Key players who manage scarce resources yet maximize opportunity."
          },
          {
            "id" : 5,
            "iconName": "icon-mobile",
            "serviceTitle": "Agnostic Enterprise Mobile",
            "sDetails" : "Our founder pioneered Agnostic Enterprise Mobile when he started JCube in 1998. The current incarnation of that strategic platform (JCube5) is part of our technology mix and we continue licensing it to companies like EdwardJones, who is in their 20th year of leveraging that platfom. Proof that strategic capabilities endure."
          },
          {
            "id" : 6,
            "iconName": "icon-gears",
            "serviceTitle": "Startup Partnering",
            "sDetails" : "As part of Next4A, we partner with multiple FinTech startups and provide strategy, technology and sweat to get them out of incubation and into Series A. Current partners include NetJavelin and Peveq."
          }
      ],
      "contact":[
        {
          "id": "1",
          "title": "Contact Info",
          menuItems: [
            {
              id: 1,
              icon: 'icon_pin_alt',
              text: 'Address: 5733 Manito Circle, Las Vegas, NV 89130',
            },
            {
              id: 2,
              icon: 'icon_phone',
              text: 'Phone: +1.702.800.3653',
            },
            {
              id: 3,
              icon: 'icon_mail',
              text: 'Email: info@nwerks.com',
            },
            {
              id: 4,
              icon: 'icon_globe',
              text: 'Website: http://NWerks.com',
            }
          ]
        },
      ],
    contacttitle: 'Contact Form',
}

export default jhonData;

